import React, { useState } from "react"

import { Box, Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import newicon from "../images/dpcgc/newicon.svg"
import { Link } from "gatsby"
// import grbmember7 from "../images/grbmember7.svg"
import grbmember7 from "../images/grbmember7.png"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  bigheading: {
    // width:"150px",
    // height:"30px",
    FontFace: "Roboto",
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
    marginleft: "334px",
    padding: "0px 10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
  },
  member: {
    height: "15rem",
    marginTop: "25px",
  },
  text: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Roboto",
    textAlign: "justify",

    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 22px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 29px",
      fontSize: "12px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "0 48px",
      fontSize: "14px",
      lineHeight: "22px",
      // paddingTop: "2px",
    },
  },
  text1: {
    color: " #000000",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "bold",
    fontFamily: "Roboto",

    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 22px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 29px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "0 48px",
      fontSize: "14px",
      lineHeight: "22px",
      // paddingTop: "44px",
      // paddingRight:"20px",
    },
  },
  leader: {
    height: "20rem",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "20rem",
    },
  },
  back: {
    color: "#BDBDBD",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
}))
// const history = useHistory();
export default function GRBMember7() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} justifyContent="center">
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/whoweare" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>ABOUT US</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/grbmembers" style={{ textDecoration: "none" }}>
            <Typography className={classes.nav}>GRB MEMBERS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/govtnotification" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              GOVT. NOTIFICATIONS
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/discolsures" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>DISCLOSURES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>FAQS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>LEADERSHIP TEAM</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          {/* <Box style={{ textAlign: "right" }}>
          <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
          <Link to="/consumerresources" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              CONSUMER RESOURCES
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Box height="8rem" />
      <Grid container justifyContent="center">
        <Grid sm={9} xs={10} md={8} item>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Link to="/grbmembers" style={{ textDecoration: "none" }}>
                <Button
                  className={classes.back}
                  size="large"
                // startIcon={<ArrowBackIosIcon />}
                >
                  <ArrowBackIosIcon />
                  Back
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="1rem" />

      <Grid container justifyContent="center">
        <Grid item sm={9} md={8} xs={10}>
          <Typography className={classes.bigheading}>GRB Members</Typography>
        </Grid>
        <Grid item sm={9} md={8} xs={10}>
          <Grid container justifyContent="center" style={{ padding: "25px 0px" }} >
            <Grid item sm={12} md={4} xs={12} lg={3} style={{ padding: "0px 10px" }}>
              {/* <Box height="6rem" /> */}
              <img src={grbmember7} className={classes.leader} />
            </Grid>
            <Grid item sm={12} md={8} xs={12} lg={9} style={{ padding: "25px 15px" }}>
              {/* <Box height="6rem" /> */}
              <Typography className={classes.text1}>Kiran Desai</Typography>
              {/* <Box height="3rem" /> */}
              <Typography className={classes.text}>
                Director, India General Counsel
              </Typography>
              <Box height="2rem" />
              <Typography className={classes.text}>
                Kiran has over 18 years of experience in Media and Entertainment Law. Having previously been a counsel in the Bombay High Court in the chambers of Shyam Divan, he also has extensive litigation experience. Kiran completed his LL.B from the Government Law College, Mumbai and his LL.M from the Georgetown University Law Center, Washington D.C., and has also been admitted as a Solicitor in England and Wales.
              </Typography>
              <Box height="2rem" />
              <Typography className={classes.text}>
                Prior to joining Netflix, Kiran was the Managing Partner at Desai & Partners, a media and entertainment law firm, based in Mumbai, where he advised clients in the media, entertainment & sports industry including celebrities, sports personalities, production houses, entertainment & media companies. He has advised clients on film and television-related transactions including financing, production, distribution (via traditional as well as new media) of films, episodic, television, and digital content.
              </Typography>
              <Box height="2rem" />
              <Typography className={classes.text}>
                Kiran is presently the General Counsel, Legal of Netflix India and has been with Netflix for 3.5 years.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="10rem" />
    </>
  )
}
